<template>
  <form-materials
    title="Formularios de Materiales"
    :showActionCreate="false"
  ></form-materials>
</template>

<script>
import FormMaterials from "@/router/views/supplying/formMaterials/index.vue";
export default {
  components: {
    FormMaterials,
  },
};
</script>

<style></style>
